import React from "react";
import Media from "react-media";
import { isMobile } from "mobile-device-detect";
import { TileWithIcon } from "./TileWithIcon";
import PictureProvider from "../../components/Picture";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";

const ALTERNATIVE_LAYOUT_NAME = "two_columns";

export type TilesWithIconsData = {
  header?: string;
  layout: string;
  background: string;
  textColor: string;
  picture: { publicUrl: string };
  element: Record<string, TileWithIcon>;
};

type TilesWithIconsProps = {
  data: TilesWithIconsData;
};

const TilesWithIcons: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const { data } = blockData as TilesWithIconsProps;

  const {
    header,
    element = {},
    layout,
    background = "#fff",
    textColor = "#2A3030",
    picture,
  } = data;

  const items: Array<TileWithIcon> = Object.values(element);
  const isAlternativeVersion = ALTERNATIVE_LAYOUT_NAME === layout;

  const containerStyle = {
    backgroundImage: "none",
    backgroundColor: background,
    color: textColor,
    backgroundSize: "cover",
    backgroundPositionY: "center",
  };

  if (picture && picture.publicUrl) {
    containerStyle.backgroundImage = `Url(${
      process.env.NEXT_PUBLIC_IMG_URL + picture.publicUrl
    })`;
  }

  const getClassForItem = (): string => {
    switch (items.length) {
      case 1:
        return "tilesWithIcons__item--100";
      case 2:
        return "tilesWithIcons__item--50";
      case 3:
        return "tilesWithIcons__item--33";

      default:
        return "";
    }
  };

  return (
    <div style={containerStyle}>
      <div className="tilesWithIcons__container">
        {header && <h2>{header}</h2>}
        <div className="tilesWithIcons__list">
          {items.map(({ title, content, picture }, index) => (
            <div
              className={`tilesWithIcons__item ${getClassForItem()} ${
                isAlternativeVersion ? "tilesWithIcons__itemAlternative" : ""
              }`}
              key={"item_" + index}
            >
              <div className="tileItem__icon">
                {picture && picture.publicUrl && (
                  <PictureProvider data={picture} />
                )}
                {!isAlternativeVersion && (
                  <React.Fragment>
                    <Media
                      query="(max-width: 1339px)"
                      defaultMatches={isMobile}
                    >
                      <div className="tileItem__icon--line" />
                    </Media>
                    <Media
                      query="(min-width: 1340px)"
                      defaultMatches={!isMobile}
                    >
                      {index + 1 < items.length && (
                        <div className="tileItem__icon--line" />
                      )}
                    </Media>
                  </React.Fragment>
                )}
              </div>
              <div className="tileItem__content">
                <h3
                  className={
                    isAlternativeVersion ? "style-as-h2" : "style-as-h3"
                  }
                >
                  {title}
                </h3>
                <div
                  className="tileItem__content--text"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TilesWithIcons;
